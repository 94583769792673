import React from 'react';
import RegisterCard from '../Components/Card/RegisterCard/RegisterCard';

const Register = () => {
    return (
        <>
            <RegisterCard />
        </>
    );
};

export default Register;
