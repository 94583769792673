import React from 'react';
import PatientReg from '../Components/PatientReg/PatientReg';

export const OPDReg = () => {
    document.title = 'Registration-Medflix';

    return (
        <>
            <PatientReg />
        </>
    );
};
